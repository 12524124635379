import { defineStore } from 'pinia'
import { useWebNotification } from '@vueuse/core'
import { getUserInfoFn, getUserDetailsFn, getUsersListFn } from '@/api/auth/users'
// import jwtDecode from 'jwt-decode' 目前沒用到

export const useUserInfo = defineStore('userInfo', {
  state: () => ({
    state: '', // 頁面狀態
    userInfoData: [], // 個人資訊
    userInfoDetails: [], // 用戶詳情 (含tenant、user、tenantAgentStatus)
    agentUsersList: [], // 用戶列表(含座席狀態)
    agentUsersMap: new Map(),
    domain: 'https://e2zfz2.com/oss', // 上傳圖片url
    twoFactorInfo: null,
    isFirstLogin: null,
    showHomeMain: false
  }),
  getters: {
    // ***
  },
  actions: {
    setToken (token) {
      localStorage.setItem('token', token)
    },
    // 獲取個人資訊
    async getUserInfo () {
      if (this.userInfoData.length > 0) { return this.userInfoData }
      const res = await getUserInfoFn()
      const data = res.data.value
      if (data.code === 200) {
        this.userInfoData = data.data
      }
      return this.userInfoData
    },
    // 獲取用戶詳情
    async getUserDetails () {
      if (this.userInfoDetails.length > 0) { return this.userInfoDetails }
      const res = await getUserDetailsFn()
      const data = res.data.value
      if (data.code === 200) {
        this.userInfoDetails = data.data

        // 判斷第一次登入
        this.isFirstLogin = data.data.tenant.isFirstLogin

        // 有用戶詳情才顯示
        if (this.userInfoDetails) {
          this.showHomeMain = true
        }

        // 儲存座席狀態
        localStorage.setItem('userState', data.data.tenantAgentStatus.status)
      }
      return this.userInfoDetails
    },
    // 獲取用戶列表(含狀態)
    async getAgentsUserList (params) {
      if (this.agentUsersList.length > 0) { return this.agentUsersList }
      const param = {
        pageNum: params.pageNum || 0,
        pageSize: params.pageSize || 20
      }
      const res = await getUsersListFn(param)
      const data = res.data.value
      if (data.code === 200) {
        this.agentUsersList = data.data.data

        // 所有座席資訊(KV值)
        this.agentUsersList.forEach((user) => {
          this.agentUsersMap.set(user.agentId, user)
        })
      }
      return this.agentUsersList
    },
    notificationAction (msg, image) { // 通知
      const { isSupported, show, close, notification } = useWebNotification()
      if (isSupported) {
        show({
          title: this.userInfoDetails.tenant.receptionText,
          body: msg,
          icon: this.domain + image
        })
      } else {
        ElMessage.warning({ message: 'Web Notification is not supported in this browser.' })
      }
    }
  }
  // persist: {  // 全局狀態管理永久化
  //   enabled: true,
  //   strategies: [
  //     {
  //       key: 'userInfo',
  //       storage: process.client ? localStorage : null
  //     }
  //   ]
  // }
})
